<template>
  <div>
    <p class="buttons">
      <input id="file" type="file" accept="audio/*" hidden @change="uploadFile"/>
      <button :disabled="!authorized || isTaping" @click="startTaping">开始录制</button>
      <button :disabled="!authorized || !isTaping" @click="stopTaping">停止录制</button>
      <button @click="selectFile">上传音频文件</button>
    </p>
    <p class="player">
      <audio :src="audioSrc" controls v-if="audioSrc && !isTaping"/>
    </p>
  </div>
</template>

<script>
import axios from "axios";
import Recorder from 'js-audio-recorder'

export default {
  name: "FuncButtons",
  data() {
    return {
      recorder: undefined,
      authorized: false,
      isTaping: false,
      audioSrc: "",
    }
  },
  methods: {
    startTaping() {
      this.recorder.start()
      console.info("开始录音...")
      this.$emit('setTip', "正在录音...")
      this.isTaping = true
    },
    stopTaping() {
      this.recorder.stop()
      console.info("录音结束...")
      this.isTaping = false
      const blob = this.recorder.getWAVBlob()
      this.uploadFile({
        file: new window.File([blob], new Date().format("yyyyMMddHHmmss.wav"), {type: blob.type})
      })
      this.audioSrc = URL.createObjectURL(blob)
    },
    selectFile() {
      const dom = document.getElementById("file")
      dom.value = ''
      dom.click()
    },
    uploadFile(e) {
      const file = e.file ? e.file : e.target.files[0]
      if (!file) return
      this.$emit('setTip', "正在识别...")
      const formData = new FormData()
      formData.append('file', file)
      axios.post("https://xiaofa-lawyer.aegis-info.com/asr", formData, {
        headers: {
          'Content-Type': "multipart/form-data",
        }
      })
          .then(res => this.$emit('setContent', res.data))
          .then(() => this.$emit('setTip', ""))
          .catch(err => this.$emit('setTip', `请求失败: ${err}`))
    }
  },
  mounted() {
    this.recorder = new Recorder({
      // sampleBits: 16, // 采样位数, 8 / 16, 默认16
      // sampleRate: 48000, // 采样率, 11025 / 16000 / 22050 / 24000 / 44100 / 48000
      // numChannels: 1, // 声道, 1 / 2, 默认1
      // compiling: false,(0.x版本中生效,1.x增加中) // 是否边录边转换，默认是false
    })
    // 录制过程
    this.recorder.onprocess = e => this.$emit('setTip', `正在录音：${e.toFixed(3)}秒...`)

    // 请求麦克风权限
    navigator.mediaDevices.getUserMedia({
      audio: true
    })
        .then(() => this.authorized = true)
        .catch(err => console.error(`获取权限失败: ${err}`))
  }
}
</script>

<style scoped>
p {
  margin-top: 0;
}

button {
  position: relative;
  margin: 10px;
  padding: 8px 20px;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid;
  cursor: pointer;
}
</style>