<template>
  <div>
    <h2 class="title">语音识别</h2>
    <textarea class="textarea" readonly v-model="content" @click="selectContent"/>
    <p class="tip">{{ tip }}</p>
    <FuncButtons @setContent="setContent" @setTip="setTip"/>
  </div>
</template>

<script>
import FuncButtons from "@/components/FuncButtons"

export default {
  name: "MainView",
  components: {
    FuncButtons
  },
  data() {
    return {
      content: "",
      tip: ""
    }
  },
  methods: {
    selectContent(e) {
      e.target.select()
      document.execCommand("Copy")
      this.tip = "*已复制到剪贴板"
      setTimeout(() => this.tip = "", 1500)
    },
    setContent(e) {
      this.content = e.asr_result
    },
    setTip(e) {
      this.tip = e
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
  line-height: 1.2;
  font-size: 32px;
  color: #074f88;
  box-sizing: border-box;
}

.textarea {
  min-width: 75%;
  max-width: 75%;
  min-height: 400px;
  font-size: 24px;
  font-weight: 600;
}

.tip {
  color: #f00;
  font-size: 18px;
  height: 18px;
  margin: 0;
}
</style>