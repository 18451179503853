<template>
  <nav class="nav">
    <ul class="nav-list">
      <li class="nav-item" v-for="(item,index) in items" :key="index">
        <a :href="item.href">{{ item.content }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      items: [{
        content: "首页",
        href: "javascript: void(0)"
      }]
    }
  }
}
</script>

<style scoped>
.nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 50px;
  background: white;
  box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.23);
}

.nav-list {
  display: flex;
  padding: 0 10px;
}

.nav-item {
  list-style-type: none;
  padding: 0 20px;
  font-size: 20px;
}
</style>
